import React from 'react';
import BlogGridComponent from 'components/blog-grid';
import Loading from 'shared/loading';
import useWordpress from 'hooks/useWordpress';

import 'styles/project.scss';

export default function BlogGrid({ pageContext }) {
  const { slug, ...props } = pageContext;
  const context = process.env.GATSBY_DEVELOPMENT ? useWordpress(props) : props;
  return context ? <BlogGridComponent {...context} slug={slug} /> : <Loading />;
}
