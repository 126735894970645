import React from 'react';
import Img from 'img';
import Icon from 'icon';
import { Link } from 'gatsby';
import Headline from 'headline';
import Container from 'container';
import BlogPost from './blog-post';
import LatestPost from './latest-post';
import NewsletterForm from '../page-templates/blog_banner_b';
import s from './blog-grid.module.scss';

export default function BlogGrid(props) {
  console.log(props, 'asdsadsadasd');
  return (
    <main className={s.root}>
      <div className={s.hero}>
        <div className={s.overlay} />
        <div className={s.imageWrapper}>
          <Img className={s.heroImage} src="generalhero" />
        </div>
        <div className={s.heroContent}>
          <div className="container">
            <div className={s.textWrapper}>
              <Headline h1 secondary wild white className={s.heroTitle}>
                {props.title || 'Blog'}
              </Headline>
              <Headline
                h3
                className={`${s.heroSubtitle} ${s.one}`}
                html="HEADLINE"
              />
              <Headline
                h3
                className={`${s.heroSubtitle} ${s.two}`}
                html="GOES HERE"
              />
            </div>
          </div>
        </div>
      </div>
      <LatestPost latestPost={Object.values(props.popularPosts.popularPosts).reverse()[0]} />
      <Container>
        <div className={s.gridWrapper}>
          {props.data.map(post => (
            <BlogPost {...post} key={post.title.rendered} className={s.post} />
          ))}
        </div>
        <ol className={s.pageNav}>
          {props.page > 1 && (
            <>
              <li className={`${s.chevron} ${s.closeSpan}`}>
                <Link to={props.slug}>
                  <Icon icon="chevron-left" />
                  <Icon icon="chevron-left" />
                </Link>
              </li>
              {' '}
              <li className={`${s.chevron} ${s.previous}`}>
                <Link
                  to={
                    props.page - 1 === 1
                      ? props.slug
                      : `${props.slug}/${props.page - 1}`
                  }
                >
                  <Icon icon="chevron-left" />
                </Link>
              </li>
            </>
          )}
          {Array(props.totalPages)
            .fill(null)
            .map((_, idx) => (
              <li
                key={idx}
                className={`${s.pageNumber} ${
                  idx + 1 === props.page ? s.active : ''
                }`}
              >
                <Link
                  to={idx + 1 === 1 ? props.slug : `${props.slug}/${idx + 1}`}
                >
                  <span>{idx + 1}</span>
                </Link>
              </li>
            ))}
          {props.page < props.totalPages && (
            <>
              <li className={`${s.chevron} ${s.next}`}>
                <Link to={`${props.slug}/${props.page + 1}`}>
                  <Icon icon="chevron-right" />
                </Link>
              </li>
              {' '}
              {props.page !== props.totalPages && (
                <li className={`${s.chevron} ${s.closeSpan} ${s.lastPage}`}>
                  <Link to={`${props.slug}/${props.totalPages}`}>
                    <Icon icon="chevron-right" />
                    <Icon icon="chevron-right" />
                  </Link>
                </li>
              )}
            </>
          )}
        </ol>
      </Container>
      <NewsletterForm
        headline="Newsletter"
        subtitle="SIGN UP TO OUR"
        button={{ text: "I'D LOVE TO" }}
      />
    </main>
  );
}
