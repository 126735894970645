import React from 'react';
import { Link } from 'gatsby';
import Icon from 'icon';
import Img from 'img';
import getDate from 'utils/get-date';
import { navigate } from '@reach/router';

import s from './blog-post.module.scss';

export default function BlogPost(props) {
  const { mainImage: image, date, excerpt, slug, title, className } = props;
  console.log(slug, title.rendered);
  return (
    <div
      className={`${s.root} ${className || ''}`}
      tabIndex="0"
      role="button"
      onClick={() => navigate(`/${slug}`)}
    >
      <div className={s.imageWrapper}>
        <Img src={image.title} className={s.image} />
      </div>
      <p className={s.date}>{getDate(date)}</p>
      <h4
        className={s.title}
        dangerouslySetInnerHTML={{ __html: title.rendered }}
      />
      <p
        className={`text-body-light ${s.excerpt}`}
        dangerouslySetInnerHTML={{ __html: excerpt }}
      />
      <div>
        <Link to={`/${slug}`} className={s.link}>
          <span>Read more</span> <Icon icon="chevron-right" />
        </Link>
      </div>
    </div>
  );
}
