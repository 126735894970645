import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';
import Img from 'img';
import s from './latest.module.scss';

export default function LatestPost({ latestPost, ...other }) {
  // data
  const postSubtitle = latestPost.categories ? latestPost.categories[0].name : 'other';
  const postTitle = latestPost.title.rendered;
  const { excerpt, slug } = latestPost;
  // tags

  return (
    <Container className={s.root}>
      <div className={s.wrapper}>
        <div className={`${s.imgWrapper}`}>
          {latestPost.mainImage && (
          <Img
            className={s.image}
            src={latestPost.mainImage.title}
            alt={latestPost.mainImage.title}
          />
          )}
          <div className={`${s.titleWrapper}`}>
            {/* <p
              className={`${s.title} ${s.capitalized}`}
              dangerouslySetInnerHTML={{ __html: postSubtitle }}
            /> */}
            <p
              className={`${s.title}`}
              dangerouslySetInnerHTML={{ __html: postTitle }}
            />
            <Button className={`${s.button}`} href={`/${slug}/`}>
              Read more
            </Button>
          </div>
        </div>
        <div className={`${s.textWrapper}`}>
          <div className={s.headlineWrapper}>
            <Headline
              h2
              wild
              className={s.headline}
            >
            Our
            </Headline>
            <Headline
              h2
              wild
              className={`${s.headline} ${s.line2}`}
            >
            Latest Post
            </Headline>
          </div>
          <p
            className={`${s.excerpt}`}
            dangerouslySetInnerHTML={{ __html: excerpt }}
          />
        </div>
      </div>
    </Container>
  );
}
